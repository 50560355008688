import React, { Component } from 'react';
import { Circle } from 'react-konva';

class LiveEvent extends Component {
  mounted = false;

  componentWillUnmount() {
    this.mounted = false;
    this.event.remove();
  }

  componentDidMount() {
    this.mounted = true;
  }

  fade() {
    if (this.event === null) {
      return;
    }
    this.event.to({
      scaleX: 0.4,
      scaleY: 0.4,
      opacity: 0.4,
      duration: 2.5,
      onFinish: () => {
        this.props.onRemove(this.props.name);
      },
    });
  }

  render() {
    return (
      <Circle
        ref={node => {
          this.event = node;
          this.fade();
        }}
        x={this.props.xPos}
        y={this.props.yPos}
        radius={this.props.size}
        fill={this.props.color}
      />
    );
  }
}

export default LiveEvent;
