import React, { Component } from 'react';
import TimeSelector from './timeselector/timeselector';
import Carehome1 from './../assets/carehome_heatmap_small.png';
import Carehome2 from './../assets/carehome_livemap_large.png';

class Carehome extends Component {
  render() {
    return (
      <div className="carehome-content">
        <div className="leftside-container">
          <div className="top-view-container">
            <div className="header">
              <div className="title">
                <span className="main">Heatmap</span>
                <span className="secondary">[A7]</span>
              </div>
            </div>
            <div className="heatmap-container">
              <img src={Carehome1} alt="heatmap" />
            </div>
          </div>
          <div className="bottom-view-container">
            <div className="header">
              <div className="title">
                <span className="main">Statistics</span>
                <span className="secondary">[A7]</span>
              </div>
            </div>
            <div className="statistics-content">
              <table className="stats-table">
                <tbody>
                  <tr>
                    <td>Period</td>
                    <td>12H</td>
                  </tr>
                  <tr>
                    <td>Lights</td>
                    <td>
                      <span className="active">ON</span>
                      <span className="inactive"> / OFF</span>
                    </td>
                  </tr>
                  <tr>
                    <td>Fall alerts</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Taps for help</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Balcony</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>Kitchen</td>
                    <td>7</td>
                  </tr>
                  <tr>
                    <td>Restroom</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td>Visitors</td>
                    <td>1</td>
                  </tr>
                  <tr>
                    <td>Movement</td>
                    <td className="green">Normal</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="rightside-container">
          <div className="view-container">
            <div className="header">
              <div className="title">
                <span className="main">Live</span>
                <span className="secondary">[Apartment A7]</span>
              </div>
            </div>
            <div className="livemap-container">
              <img src={Carehome2} alt="livemap" />
            </div>
          </div>
        </div>
        <div className="bottom-bar-container">
          <div className="bottom-bar">
            <TimeSelector
              entries={[]}
              onTimeSelected={timestamp => this.selectTimestamp(timestamp)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Carehome;
