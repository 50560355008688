const endpoints = {
  ws: {
    beta: 'wss://cgrf5omqjj.execute-api.us-east-1.amazonaws.com/dev/',
    alpha: 'wss://d3kiy85dwh.execute-api.us-east-1.amazonaws.com/dev/',
    dev: 'wss://vckezsy7e3.execute-api.us-east-1.amazonaws.com/dev/',
  },
  http: {
    beta: 'https://6x5jiz8gt9.execute-api.us-east-1.amazonaws.com/dev/',
    alpha: 'https://f45fvcheo4.execute-api.us-east-1.amazonaws.com/dev/',
    dev: 'https://m0atznbs8f.execute-api.us-east-1.amazonaws.com/dev/',
  },
  sms: {
    beta: 'https://2b6faeqyea.execute-api.us-east-1.amazonaws.com/dev/',
    alpha: 'https://76cz004j17.execute-api.us-east-1.amazonaws.com/dev/',
    dev: 'https://uyf4q247ag.execute-api.us-east-1.amazonaws.com/dev/',
  },
};

class Utils {
  static getWsEndpointByEnvironment() {
    switch (process.env.REACT_APP_ENV) {
      case 'beta':
        return endpoints.ws.beta;
      case 'alpha':
        return endpoints.ws.alpha;
      default:
        return endpoints.ws.dev;
    }
  }

  static getHttpEndpointByEnvironment() {
    switch (process.env.REACT_APP_ENV) {
      case 'beta':
        return endpoints.http.beta;
      case 'alpha':
        return endpoints.http.alpha;
      default:
        return endpoints.http.dev;
    }
  }

  static getSmsEndpointByEnvironment() {
    switch (process.env.REACT_APP_ENV) {
      case 'beta':
        return endpoints.sms.beta;
      case 'alpha':
        return endpoints.sms.alpha;
      default:
        return endpoints.sms.dev;
    }
  }
}

export default Utils;
