import React, { Component } from 'react';
import simpleheat from 'simpleheat';

const radiusFactor = 0.5;

class Heatmap extends Component {
  constructor(props) {
    super(props);
    this.onResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    console.log('Heatmap mounted');
    this.prepareHeatmapCanvas();
    window.addEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.drawEventsByTimestamp();
    }
    if (this.props.enableGrid) {
      this.drawBaseGrid();
    }
  }

  shouldComponentUpdate() {
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  handleResize() {
    this.prepareHeatmapCanvas();
  }

  prepareHeatmapCanvas() {
    let floorAreaRatio;
    if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
      floorAreaRatio = this.props.xTiles / this.props.yTiles;
    } else {
      floorAreaRatio = this.props.yTiles / this.props.xTiles;
    }
    this.canvas = document.getElementById('room1-heatmap');
    const canvasContainer = document.getElementById('heatmap-container');
    const canvasWidth = canvasContainer.getBoundingClientRect().width;
    const canvasHeight = canvasContainer.getBoundingClientRect().height;
    let width;
    let height;
    if (canvasWidth <= canvasHeight) {
      if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
        width = parseInt(canvasWidth * floorAreaRatio);
        height = parseInt(canvasWidth);
      } else {
        width = parseInt(canvasWidth);
        height = parseInt(canvasWidth * floorAreaRatio);
      }
    } else {
      if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
        width = parseInt(canvasHeight * floorAreaRatio);
        height = parseInt(canvasHeight);
      } else {
        width = parseInt(canvasHeight);
        height = parseInt(canvasHeight * floorAreaRatio);
      }
    }
    this.canvas.setAttribute('width', width);
    this.canvas.setAttribute('height', height);
    this.heatmap = simpleheat('room1-heatmap');
    if (this.props.events.length !== 0) {
      this.drawEventsByTimestamp();
    }
    if (this.props.enableGrid) {
      this.drawBaseGrid();
    }
  }

  drawEventsByTimestamp() {
    const refWidth = this.canvas.getAttribute('width') / this.props.xTiles;
    const refHeight = this.canvas.getAttribute('height') / this.props.yTiles;
    const eventSize = refWidth * radiusFactor;
    let refTimestamp = this.props.selectedTimestamp;
    if (this.props.selectedTimestamp === null) {
      refTimestamp = Date.now();
    }
    if (this.props.reset !== null) {
      refTimestamp = this.props.reset;
    }
    const alerts = [];
    const filteredEvents = this.props.events.map(event => {
      const eventTimestamp = event.timestamp * 1000;
      const x = refWidth * event.xPos + eventSize;
      const y = refHeight * event.yPos + eventSize;
      if (eventTimestamp <= refTimestamp && this.props.reset === null) {
        if (
          event.eventInfo === 'alert' ||
          event.eventInfo === 'tap' ||
          event.eventInfo === 'fall'
        ) {
          alerts.push(event);
        }
        return [x, y, this.props.force];
      } else if (eventTimestamp >= refTimestamp && this.props.reset !== null) {
        if (
          event.eventInfo === 'alert' ||
          event.eventInfo === 'tap' ||
          event.eventInfo === 'fall'
        ) {
          alerts.push(event);
        }
        return [x, y, this.props.force];
      }
      return [];
    });
    this.heatmap.data(filteredEvents);
    this.heatmap.radius(eventSize, eventSize);
    this.heatmap.draw();
    this.drawAlertRectangles(alerts);
  }

  drawBaseGrid() {
    this.context = document.getElementById('room1-heatmap').getContext('2d');
    const canvasWidth = this.canvas.getAttribute('width');
    const canvasHeight = this.canvas.getAttribute('height');
    const refWidth = canvasWidth / this.props.xTiles;
    const refHeight = canvasHeight / this.props.yTiles;
    this.context.globalAlpha = 1;
    this.context.beginPath();
    for (var x = 0; x <= canvasWidth; x += refWidth) {
      this.context.moveTo(x, 0);
      this.context.lineTo(x, canvasHeight);
    }
    for (var y = 0; y <= canvasHeight; y += refHeight) {
      this.context.moveTo(0, y);
      this.context.lineTo(canvasWidth, y);
    }
    this.context.strokeStyle = '#bcbec0';
    this.context.stroke();
  }

  drawAlertRectangles(events) {
    const refWidth = this.canvas.getAttribute('width') / this.props.xTiles;
    const refHeight = this.canvas.getAttribute('height') / this.props.yTiles;
    const eventSize = refWidth * radiusFactor;
    events.forEach(event => {
      const xPos = refWidth * event.xPos + eventSize;
      const yPos = refHeight * event.yPos + eventSize;
      const date = new Date(event.timestamp * 1000).toString();
      this.drawAlertRectangle(xPos, yPos, date);
    });
  }

  drawAlertRectangle(xPos, yPos, date) {
    const matchOfTime = date.match(/(\d\d:\d\d:\d\d)/g);
    const dateString = date.substring(0, date.indexOf(matchOfTime) - 1);
    const timeString = date.substring(
      date.indexOf(matchOfTime),
      date.indexOf('GMT') - 1
    );
    this.context = document.getElementById('room1-heatmap').getContext('2d');
    var x = xPos;
    var y = yPos;
    var width = 100;
    var height = 40;
    var radius = 10;
    let position;
    if (parseInt(xPos) - width < 0) {
      if (parseInt(yPos) - height < 0) {
        position = 'topleft';
      } else {
        position = 'bottomleft';
        y = y - height;
      }
    } else {
      if (parseInt(yPos) - height < 0) {
        position = 'topright';
        x = x - width;
      } else {
        position = 'bottomright';
        y = y - height;
        x = x - width;
      }
    }
    this.context.globalAlpha = 1;
    this.context.fillStyle = '#CA4F24';
    this.context.strokeStyle = 'white';
    this.context.beginPath();
    this.context.moveTo(x + radius, y);
    if (position === 'topright') {
      this.context.lineTo(x + width, y);
    } else {
      this.context.lineTo(x + width - radius, y);
      this.context.quadraticCurveTo(x + width, y, x + width, y + radius);
    }
    if (position === 'bottomright') {
      this.context.lineTo(x + width, y + height);
    } else {
      this.context.lineTo(x + width, y + height - radius);
      this.context.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
    }
    if (position === 'bottomleft') {
      this.context.lineTo(x, y + height);
    } else {
      this.context.lineTo(x + radius, y + height);
      this.context.quadraticCurveTo(x, y + height, x, y + height - radius);
    }
    if (position === 'topleft') {
      this.context.lineTo(x, y);
    } else {
      this.context.lineTo(x, y + radius);
      this.context.quadraticCurveTo(x, y, x + radius, y);
    }
    this.context.closePath();
    this.context.stroke();
    this.context.fill();
    this.context.fillStyle = 'white';
    this.context.fillText(dateString, x + 10, y + height / 2);
    this.context.fillText(timeString, x + 10, y + height / 2 + 10);
  }

  render() {
    return (
      <div className="canvas-container" id="heatmap-container">
        <canvas id="room1-heatmap" className="canvas" />
      </div>
    );
  }
}

export default Heatmap;
