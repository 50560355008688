import React, { Component } from 'react';
import ImgneLogo from './../assets/Imagine_IM_logo_1_smaller.png';
import SearchIcon from './../assets/ICON_search.png';
import userPicture from './../assets/Imagine_IM_icon_smaller.png';

class Header extends Component {
  render() {
    return (
      <header className="header-container">
        <div className="logo-container">
          <a href="http://imgne.com" target="_blank" rel="noopener noreferrer">
            <img className="logo" src={ImgneLogo} alt="header-logo" />
          </a>
        </div>
        <div className="search-container">
          <button className="search-button">
            <img className="search-icon" src={SearchIcon} alt="Search" />
          </button>
          <input type="text" className="search-input" placeholder="Search..." />
        </div>
        <div className="user-container">
          <div className="user-details">
            <span id="fullname">Visitor</span>
            <span id="email">visitor@imgne.com</span>
          </div>
          <img src={userPicture} className="user-picture" alt="user profile" />
        </div>
      </header>
    );
  }
}

export default Header;
