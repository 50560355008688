import axios from 'axios';
import Utils from './utils';

const baseUrl = Utils.getHttpEndpointByEnvironment();

class EventClient {
  static constructPath(params) {
    return (
      'events?roomId=' +
      params.roomId +
      '&startTime=' +
      params.startTime +
      '&endTime=' +
      params.endTime
    );
  }

  static async getEvents(roomId, startTime, endTime) {
    const path = this.constructPath({
      roomId: roomId,
      startTime: startTime,
      endTime: endTime,
    });
    return new Promise((resolve, reject) => {
      axios
        .get(baseUrl + path)
        .then(response => {
          if (response.data.length !== 0) {
            resolve(response.data);
          }
          resolve([]);
        })
        .catch(error => {
          console.log(error);
          reject(error);
        });
    });
  }
}

export default EventClient;
