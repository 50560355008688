import React, { Component } from 'react';
import { Circle } from 'react-konva';

class AlertEvent extends Component {
  mounted = false;

  componentDidMount() {
    this.mounted = true;
    this.blinkInterval = setInterval(() => this.blink(), 1500);
  }

  componentWillUnmount() {
    this.mounted = false;
    this.event.remove();
    clearInterval(this.blinkInterval);
  }

  handleOnClick() {
    this.event.remove();
    clearInterval(this.blinkInterval);
    this.props.onRemove(this.props.name);
  }

  blink() {
    if (this.event === null) {
      return;
    }
    this.event.opacity(1);
    this.event.to({
      opacity: 0,
      duration: 1.5,
    });
  }

  render() {
    return (
      <Circle
        ref={node => {
          this.event = node;
        }}
        x={this.props.xPos}
        y={this.props.yPos}
        radius={this.props.size}
        fill={this.props.color}
        onClick={() => this.handleOnClick()}
        onTap={() => this.handleOnClick()}
      />
    );
  }
}

export default AlertEvent;
