import React, { Component } from 'react';
import timeIcon from './../../assets/ICON_time.png';

class TimeSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTimeIndex: null,
      currentTime: new Date().getTime(),
    };
  }

  componentDidMount() {
    this.setCurrentTimeClock();
  }

  componentWillUnmount() {
    clearInterval(this.clockInterval);
  }

  tick() {
    this.setState({
      currentTime: new Date().getTime(),
    });
  }

  getLocalTimeValue(index) {
    if (index !== null) {
      const timestamp = this.props.entries[index].timestamp;
      const timestring = new Date(timestamp * 1000).toString();
      return timestring.substring(0, timestring.indexOf('GMT') - 1);
    }
    const timestring = new Date(this.state.currentTime).toString();
    return timestring.substring(0, timestring.indexOf('GMT') - 1);
  }

  getTimestampValue(index) {
    if (index !== null) {
      const timestamp = this.props.entries[index].timestamp;
      return timestamp * 1000;
    }
    return this.state.currentTime;
  }

  getSliderMaxValue() {
    if (this.props.entries.length === 0) {
      return this.props.entries.length + 1;
    } else {
      return this.props.entries.length;
    }
  }

  getSliderValue() {
    if (
      this.state.selectedTimeIndex !== null &&
      this.props.entries.length !== 0
    ) {
      return this.state.selectedTimeIndex;
    } else if (
      this.state.selectedTimeIndex === null &&
      this.props.entries.length === 0
    ) {
      return this.props.entries.length + 1;
    } else {
      return this.props.entries.length;
    }
  }

  setCurrentTimeClock() {
    this.clockInterval = setInterval(() => this.tick(), 1000);
  }

  handleTimeChange(event) {
    if (this.props.entries.length !== 0) {
      if (
        this.props.entries.length.toString() === event.target.value.toString()
      ) {
        console.log('Clock set to real time');
        this.setState({
          selectedTimeIndex: null,
        });
        this.props.onTimeSelected(null);
      } else {
        this.setState({
          selectedTimeIndex: event.target.value,
        });
        this.props.onTimeSelected(this.getTimestampValue(event.target.value));
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="icon-container">
          <img src={timeIcon} id="time-icon" alt="time icon" />
        </div>
        <span>{this.getLocalTimeValue(this.state.selectedTimeIndex)}</span>
        <div className="slider-container">
          <input
            id="date-time-picker"
            type="range"
            className="slider"
            min={0}
            value={this.getSliderValue()}
            max={this.getSliderMaxValue()}
            onChange={event => this.handleTimeChange(event)}
          />
        </div>
      </React.Fragment>
    );
  }
}
export default TimeSelector;
