import React, { Component } from 'react';
import Stats1 from './../assets/stats_heatmap_1.png';
import Stats2 from './../assets/stats_heatmap_2.png';
import Stats3 from './../assets/stats_heatmap_3.png';
import Stats4 from './../assets/stats_heatmap_4.png';
import Stats5 from './../assets/stats_heatmap_5.png';
import Stats6 from './../assets/stats_heatmap_6.png';
import TimeSelector from './timeselector/timeselector';

class Stats extends Component {
  render() {
    return (
      <div className="stats-content">
        <div className="view-containers">
          <div className="parallel-view-container">
            <div className="header">
              <div className="title">
                <span className="main">Statistics</span>
                <span className="secondary">[ROOM 1, 12h]</span>
              </div>
            </div>
            <div className="heatmap-container">
              <img src={Stats1} alt="Stats 1" />
            </div>
            <div className="stats-container">
              <table>
                <tbody>
                  <tr>
                    <td>Visitors today</td>
                    <td>241</td>
                    <td>Alerts</td>
                    <td>2</td>
                  </tr>
                  <tr>
                    <td>Visitors all time</td>
                    <td>3870</td>
                    <td>Need assistance</td>
                    <td>12</td>
                  </tr>
                  <tr>
                    <td>Demo area visits</td>
                    <td>89</td>
                    <td>Personnel present</td>
                    <td>5</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="graph-container">
              <img src={Stats4} alt="Stats 4" />
            </div>
          </div>
          <div className="parallel-view-container">
            <div className="header">
              <div className="title">
                <span className="main">Statistics</span>
                <span className="secondary">[ROOM 2, 12h]</span>
              </div>
            </div>
            <div className="heatmap-container">
              <img src={Stats2} alt="Stats 2" />
            </div>
            <div className="stats-container">
              <table>
                <tbody>
                  <tr>
                    <td>Visitors today</td>
                    <td>134</td>
                    <td>Alerts</td>
                    <td>0</td>
                  </tr>
                  <tr>
                    <td>Visitors all time</td>
                    <td>1878</td>
                    <td>Need assistance</td>
                    <td>4</td>
                  </tr>
                  <tr>
                    <td>Demo area visits</td>
                    <td>43</td>
                    <td>Personnel present</td>
                    <td>2</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="graph-container">
              <img src={Stats5} alt="Stats 5" />
            </div>
          </div>
          <div className="parallel-view-container">
            <div className="header">
              <div className="title">
                <span className="main">Statistics</span>
                <span className="secondary">[ROOM 3, 12h]</span>
              </div>
            </div>
            <div className="heatmap-container">
              <img src={Stats3} alt="Stats 3" />
            </div>
            <div className="stats-container">
              <table>
                <tbody>
                  <tr>
                    <td>Visitors today</td>
                    <td>172</td>
                    <td>Alerts</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td>Visitors all time</td>
                    <td>2221</td>
                    <td>Need assistance</td>
                    <td>16</td>
                  </tr>
                  <tr>
                    <td>Demo area visits</td>
                    <td>58</td>
                    <td>Personnel present</td>
                    <td>6</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="graph-container">
              <img src={Stats6} alt="Stats 6" />
            </div>
          </div>
        </div>
        <div className="bottom-bar-container">
          <div className="bottom-bar">
            <TimeSelector
              entries={[]}
              onTimeSelected={timestamp => this.selectTimestamp(timestamp)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Stats;
