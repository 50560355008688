import React, { Component } from 'react';
import Heatmap from './heatmap/heatmap';
import Livemap from './livemap/livemap';
import TimeSelector from './timeselector/timeselector';
import SessionHandler from '../data/sessionhandler';
import EventClient from '../data/eventclient';
import SettingsHandler from '../data/settingshandler';
import Notify from '../data/notify';

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      liveEvents: [],
      events: [],
      selectedTimestamp: null,
      settings: SettingsHandler.loadSettings(),
      resetPoint: null,
    };
  }

  /* LIFE CYCLE METHODS */
  componentDidMount() {
    this.getOldEvents();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.eventBuffer !== this.props.eventBuffer &&
      this.props.eventBuffer.length !== 0
    ) {
      this.createEvent(
        this.props.eventBuffer[this.props.eventBuffer.length - 1]
      );
      this.props.clearEventBuffer();
    }
  }

  componentWillUnmount() {
    this.saveEvents();
  }

  /* COMPONENT METHODS */
  getOldEvents() {
    this.props.clearAlerts();
    const eventBuffer = this.props.eventBuffer.slice();
    let events = SessionHandler.loadEvents();
    if (events !== null && events.length !== 0) {
      events = events.concat(eventBuffer);
      this.props.clearEventBuffer();
      const cleanedEvents = this.cleanEvents(events);
      this.setState({ events: cleanedEvents });
      this.checkAlertCountToday(cleanedEvents);
    } else {
      this.getEventsFromBackend();
    }
  }

  getEventsFromBackend() {
    console.log('Fetching events from backend');
    const settings = SettingsHandler.loadSettings();
    const roomId = settings.roomId;
    const endTime = parseInt(Date.now() / 1000);
    const startTime = endTime - 60 * 60 * 24 * parseInt(settings.timespan);
    EventClient.getEvents(roomId, startTime, endTime)
      .then(events => {
        console.log('Fetched ' + events.length + ' events!');
        const eventBuffer = this.props.eventBuffer.slice();
        events.concat(eventBuffer);
        this.props.clearEventBuffer();
        const cleanedEvents = this.cleanEvents(events);
        this.checkAlertCountToday(cleanedEvents);
        this.setState({ events: cleanedEvents });
      })
      .catch(error => {
        console.log('Error: ' + error);
      });
  }

  checkAlertCountToday(events) {
    console.log('Checking alert count today');
    const currentDate = new Date().toDateString();
    const currentDateInEpoch = new Date(currentDate).getTime() / 1000;
    events.forEach(event => {
      if (
        (event.eventInfo === 'alert' ||
          event.eventInfo === 'fall' ||
          event.eventInfo === 'tap') &&
        event.timestamp >= currentDateInEpoch
      ) {
        this.props.onAlert();
      }
    });
  }

  cleanEvents(events) {
    const cleanedEvents = [];
    events.forEach(event => {
      if (
        this.checkIfEventInsideOfBounds(event.xPos, event.yPos) &&
        this.parseEventBySettings(event)
      ) {
        cleanedEvents.push(event);
      }
    });
    return cleanedEvents;
  }

  checkIfEventInsideOfBounds(xPos, yPos) {
    if (
      xPos <= this.state.settings.floorAreaX - 1 &&
      yPos <= this.state.settings.floorAreaY - 1
    ) {
      return true;
    }
  }

  parseEventBySettings(event) {
    let value = true;
    if (parseInt(this.state.settings.roomId) !== event.roomId) {
      value = false;
    }
    if (this.state.settings.eventType !== event.eventType) {
      value = false;
    }
    // DISABLED
    if (!this.state.settings.enableAlerts) {
      if (
        event.eventInfo === 'tap' ||
        event.eventInfo === 'fall' ||
        event.eventInfo === 'alert'
      ) {
        value = false;
      }
    }
    return value;
  }

  saveEvents() {
    if (this.state.events !== null && this.state.events.length !== 0) {
      SessionHandler.saveEvents(this.state.events);
    }
  }

  selectTimestamp(timestamp) {
    this.setState({
      selectedTimestamp: timestamp,
      resetPoint: null,
    });
  }

  createEvent(event) {
    if (
      this.parseEventBySettings(event) &&
      this.checkIfEventInsideOfBounds(event.xPos, event.yPos)
    ) {
      const events = this.state.events.slice();
      events.push(event);
      this.setState({
        liveEvents: [event],
        events: events,
      });
      SessionHandler.saveEvents(events);
      if (
        event.eventInfo === 'alert' ||
        event.eventInfo === 'tap' ||
        event.eventInfo === 'fall'
      ) {
        this.props.onAlert();
        if (
          this.state.settings.smsAlerts &&
          this.state.settings.phoneNumber !== ''
        ) {
          Notify.sendNotification(
            event.roomId,
            this.state.settings.phoneNumber,
            event.eventInfo
          )
            .then(response => {
              console.log('SMS alert sent');
            })
            .catch(error => {
              console.log('SMS alert failed to send');
            });
        }
      }
    }
  }

  setHeatmapReset() {
    this.setState({
      resetPoint: Date.now(),
      selectedTimestamp: null,
    });
  }

  render() {
    return (
      <div className="dashboard-content">
        <div className="view-container">
          <div className="header">
            <div className="header-title">
              <span className="main">Live</span>
              <span className="secondary">
                [Room {this.state.settings.roomId}]
              </span>
            </div>
          </div>
          <Livemap
            liveEvents={this.state.liveEvents}
            xTiles={this.state.settings.floorAreaX}
            yTiles={this.state.settings.floorAreaY}
            fadeLatestEvent={this.state.settings.fadeLatestEvent}
            enableGrid={this.state.settings.enableGrid}
          />
          <div className="bottom-container" />
        </div>
        <div className="spacer" />
        <div className="view-container">
          <div className="header">
            <div className="header-title">
              <span className="main">Heatmap</span>
              <span className="secondary">
                [Room {this.state.settings.roomId}]
              </span>
            </div>
            <div className="header-button">
              <button onClick={() => this.setHeatmapReset()}>Reset</button>
            </div>
          </div>
          <Heatmap
            xTiles={this.state.settings.floorAreaX}
            yTiles={this.state.settings.floorAreaY}
            force={this.state.settings.forceValue}
            enableGrid={this.state.settings.enableGrid}
            events={this.state.events}
            selectedTimestamp={this.state.selectedTimestamp}
            reset={this.state.resetPoint}
          />
          <div className="bottom-container" />
        </div>
        <div className="bottom-bar-container">
          <div className="bottom-bar">
            <TimeSelector
              entries={this.state.events}
              onTimeSelected={timestamp => this.selectTimestamp(timestamp)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Dashboard;
