import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import dashboardIcon from './../assets/ICON_dashboard.png';
import roomIcon from './../assets/ICON_room.png';
import statsIcon from './../assets/ICON_statistics.png';
import settingsIcon from './../assets/ICON_settings.png';
import toggleSidebarIcon from './../assets/ICON_min_max.png';

class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      sidebar: true,
    };
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.sidebar !== this.state.sidebar) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  toggleSidebar() {
    this.setState({ sidebar: !this.state.sidebar });
  }

  render() {
    return (
      <section
        className={
          'sidebar-container' + (this.state.sidebar ? '' : ' collapsed')
        }
      >
        <div className="sidebar-toggle" onClick={() => this.toggleSidebar()}>
          <img className="icon" src={toggleSidebarIcon} alt="toggle" />
        </div>
        <nav className="sidebar-nav">
          <ul>
            <NavLink exact={true} activeClassName="nav-selected" to="/">
              <li>
                <img
                  src={dashboardIcon}
                  id="dashboard-icon"
                  alt="dashboard icon"
                />
                <span className="nav-title">Dashboard</span>
              </li>
            </NavLink>
            <NavLink exact={true} activeClassName="nav-selected" to="/retail">
              <li>
                <img src={roomIcon} className="room-icon" alt="room icon" />
                <span className="nav-title">Retail</span>
              </li>
            </NavLink>
            <NavLink exact={true} activeClassName="nav-selected" to="/carehome">
              <li>
                <img src={roomIcon} className="room-icon" alt="room icon" />
                <span className="nav-title">Care home</span>
              </li>
            </NavLink>
            <li>
              <img src={roomIcon} className="room-icon" alt="room icon" />
              <span className="nav-title">Hotel</span>
            </li>
            <NavLink exact={true} activeClassName="nav-selected" to="/stats">
              <li>
                <img src={statsIcon} className="stats-icon" alt="stats icon" />
                <span className="nav-title">Statistics</span>
              </li>
            </NavLink>
            <NavLink exact={true} activeClassName="nav-selected" to="/settings">
              <li>
                <img
                  src={settingsIcon}
                  className="settings-icon"
                  alt="settings icon"
                />
                <span className="nav-title">Settings</span>
              </li>
            </NavLink>
          </ul>
        </nav>
      </section>
    );
  }
}

export default Sidebar;
