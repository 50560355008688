class SessionHandler {
  static saveEvents(events) {
    console.log('Saving events to cache');
    sessionStorage.setItem('EventData', JSON.stringify(events));
  }

  static loadEvents() {
    console.log('Fetching cached events');
    return JSON.parse(sessionStorage.getItem('EventData'));
  }

  static clearEvents() {
    console.log('Clearing cached events');
    sessionStorage.removeItem('EventData');
  }
}

export default SessionHandler;
