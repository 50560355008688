import Utils from './utils';
import axios from 'axios';

class Notify {
  static async sendNotification(roomId, recipient, alertType) {
    if (roomId !== null && recipient !== null && alertType !== null) {
      const baseUrl = Utils.getSmsEndpointByEnvironment();
      return new Promise((resolve, reject) => {
        axios
          .post(baseUrl + 'notify', {
            roomId: roomId,
            phoneNumber: recipient,
            alertType: alertType,
          })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            console.log('Error: ' + JSON.stringify(error));
            reject(error);
          });
      });
    }
  }
}

export default Notify;
