import React, { Component } from 'react';
import toggleSidebarIcon from './../assets/ICON_min_max.png';
import statsIcon from './../assets/ICON_statistics.png';

class RightSidebar extends Component {
  constructor() {
    super();
    this.state = {
      rightSidebar: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.rightSidebar !== this.state.rightSidebar) {
      window.dispatchEvent(new Event('resize'));
    }
  }

  toggleSidebar() {
    this.setState({ rightSidebar: !this.state.rightSidebar });
  }

  renderStats() {
    switch (this.props.path) {
      case '/carehome':
        return (
          <React.Fragment>
            <ul>
              <li>
                <span className="stats-header">Falls [12h]</span>
                <span className="stats-main-value blue">0</span>
                <span className="stats-secondary-value green">[-2]</span>
              </li>
              <li>
                <span className="stats-header">WC/RESTROOM [12h]</span>
                <span className="stats-main-value blue">8</span>
                <span className="stats-secondary-value red">[+3]</span>
              </li>
              <li>
                <span className="stats-header">MOVEMENT</span>
                <span className="stats-main-value green">NORMAL</span>
              </li>
            </ul>
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <ul>
              <li>
                <span className="stats-header">Visitors today</span>
                <span className="stats-main-value blue">241</span>
                <span className="stats-secondary-value green">[+72]</span>
              </li>
              <li>
                <span className="stats-header">Visitors all time</span>
                <span className="stats-main-value blue">3870</span>
              </li>
              <li>
                <span className="stats-header">Alerts today</span>
                <span className="stats-main-value red">
                  {this.props.alerts}
                </span>
              </li>
            </ul>
          </React.Fragment>
        );
    }
  }

  render() {
    return (
      <div
        className={
          'right-sidebar-container' +
          (this.state.rightSidebar ? '' : ' collapsed')
        }
      >
        <div className="toggle-container" onClick={() => this.toggleSidebar()}>
          <img className="icon" src={toggleSidebarIcon} alt="toggle" />
        </div>
        <div className="content-container">
          <div className="leftside-container">
            <img src={statsIcon} className="stats-icon" alt="stats icon" />
          </div>
          <div className="rightside-container">
            <div className="header">Quick stats</div>
            <div className="content">{this.renderStats()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default RightSidebar;
