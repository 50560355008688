import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Utils from './data/utils';
import Sockette from 'sockette';
import Header from './components/header';
import Sidebar from './components/sidebar';
import Dashboard from './components/dashboard';
import Stats from './components/stats';
import Retail from './components/retail';
import Carehome from './components/carehome';
import Settings from './components/settings.js';
import RightSidebar from './components/rightsidebar';
import SignIn from './components/signin';
import './styles/css/main.css';

const endpoint = Utils.getWsEndpointByEnvironment();

class App extends Component {
  constructor() {
    super();
    this.state = {
      alerts: 0,
      eventBuffer: [],
      signedIn: false,
    };
  }

  logIn() {
    this.setState({ signedIn: true });
  }

  logOut() {
    this.ws.close();
    this.ws = null;
    this.setState({ signedIn: false });
  }

  componentDidUpdate() {
    if (this.state.signedIn && (this.ws === null || this.ws === undefined)) {
      this.ws = new Sockette(endpoint, {
        onclose: event => this.socketClosed(event),
        onopen: event => this.socketOpened(event),
        onerror: event => this.socketError(event),
        onmessage: event => this.socketMessage(event),
        onreconnect: event => this.socketReconnect(event),
      });
    }
  }

  componentWillUnmount() {
    this.ws.close();
  }

  socketClosed(event) {
    console.log('Websocket closed');
  }

  socketOpened(event) {
    console.log('Websocket opened');
  }

  socketError(event) {
    console.log('Websocket error: ' + JSON.stringify(event));
  }

  socketReconnect(event) {
    console.log('Websocket reconnecting: ' + JSON.stringify(event));
  }

  socketMessage(event) {
    console.log('Message received: ' + event.data);
    const message = JSON.parse(JSON.parse(event.data));
    const eventBuffer = this.state.eventBuffer.slice();
    eventBuffer.push(message.data);
    this.setState({ eventBuffer: eventBuffer });
  }

  clearEventBuffer() {
    this.setState({ eventBuffer: [] });
  }

  addAlert() {
    this.setState(prevState => ({
      alerts: prevState.alerts + 1,
    }));
  }

  clearAlerts() {
    this.setState({
      alerts: 0,
    });
  }

  render() {
    if (this.state.signedIn) {
      return (
        <Router>
          <div className="App">
            <Header />
            <section className="main-container" id="main-container">
              <Sidebar />
              <section className="content-container">
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Dashboard
                      onAlert={() => this.addAlert()}
                      clearAlerts={() => this.clearAlerts()}
                      eventBuffer={this.state.eventBuffer}
                      clearEventBuffer={() => this.clearEventBuffer()}
                    />
                  )}
                />
                <Route exact path="/retail" render={() => <Retail />} />
                <Route exact path="/carehome" render={() => <Carehome />} />
                <Route exact path="/stats" render={() => <Stats />} />
                <Route
                  exact
                  path="/settings"
                  render={() => <Settings onSignOut={() => this.logOut()} />}
                />
              </section>
              <Route
                render={({ location }) => {
                  if (
                    location.pathname === '/settings' ||
                    location.pathname === '/stats'
                  ) {
                    return null;
                  }
                  return (
                    <RightSidebar
                      alerts={this.state.alerts}
                      path={location.pathname}
                    />
                  );
                }}
              />
            </section>
          </div>
        </Router>
      );
    } else {
      return (
        <Router>
          <Switch>
            <Redirect exact from="/settings" to="" />
            <SignIn onSignIn={() => this.logIn()} />
          </Switch>
        </Router>
      );
    }
  }
}

export default App;
