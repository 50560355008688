const defaultSettings = {
  eventType: 'normal',
  roomId: '0',
  timespan: '1',
  floorAreaX: '10',
  floorAreaY: '7',
  enableAlerts: false,
  fadeLatestEvent: false,
  forceValue: '0.05',
  phoneNumber: '',
  smsAlerts: false,
  enableGrid: false,
};

class SettingsHandler {
  static saveSettings(settings) {
    console.log('Saving settings');
    const validatedSettings = this.validateSettings(settings);
    localStorage.setItem('settings', JSON.stringify(validatedSettings));
  }

  static validateSettings(settings) {
    if (settings.roomId < 0) {
      settings.roomId = 0;
    }
    if (settings.forceValue <= 0) {
      settings.forceValue = 0.01;
    }
    if (settings.floorAreaX <= 0) {
      settings.floorAreaX = 1;
    }
    if (settings.floorAreaY <= 0) {
      settings.floorAreaY = 1;
    }
    if (settings.timespan <= 0) {
      settings.timespan = 1;
    }
    return settings;
  }

  static loadSettings() {
    console.log('Loading settings');
    const settings = JSON.parse(localStorage.getItem('settings'));
    if (
      settings !== null &&
      Object.keys(defaultSettings).length === Object.keys(settings).length
    ) {
      return settings;
    } else {
      return defaultSettings;
    }
  }
}

export default SettingsHandler;
