import React, { Component } from 'react';
import { Stage, Layer } from 'react-konva';
import LiveEvent from './liveEvent';
import AlertEvent from './alertEvent';

const radiusFactor = 0.5;

class Livemap extends Component {
  mounted = false;

  constructor() {
    super();
    this.state = {
      liveEventCollection: [],
      alertEventCollection: [],
      stageSize: {},
      scaleX: 1,
      scaleY: 1,
    };
    this.onResize = this.handleResize.bind(this);
  }

  componentDidMount() {
    console.log('Livemap mounted');
    this.prepareLivemapCanvas();
    this.prepareBaseGridCanvas();
    window.addEventListener('resize', this.onResize);
    this.mounted = true;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.mounted) {
      if (prevProps.liveEvents !== this.props.liveEvents) {
        this.props.liveEvents.forEach(event => {
          if (
            event.eventInfo === 'alert' ||
            event.eventInfo === 'tap' ||
            event.eventInfo === 'fall'
          ) {
            this.createAlertEvent(event);
          } else {
            this.createLiveEvent(event);
          }
        });
      }
      if (
        prevState.stageSize.width !== undefined &&
        prevState.stageSize.height !== undefined
      ) {
        if (prevState.stageSize !== this.state.stageSize) {
          this.setState({
            scaleX:
              (parseInt(this.state.stageSize.width) /
                parseInt(prevState.stageSize.width)) *
              this.state.scaleX,
            scaleY:
              (parseInt(this.state.stageSize.height) /
                parseInt(prevState.stageSize.height)) *
              this.state.scaleY,
          });
        }
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    this.mounted = false;
  }

  handleResize() {
    this.prepareLivemapCanvas();
    this.prepareBaseGridCanvas();
  }

  clearCanvas() {
    this.setState({
      liveEventCollection: [],
      alertEventCollection: [],
    });
  }

  drawBaseGrid() {
    this.context = document.getElementById('livemap-basegrid').getContext('2d');
    const canvasWidth = this.canvas.getAttribute('width');
    const canvasHeight = this.canvas.getAttribute('height');
    const refWidth = canvasWidth / this.props.xTiles;
    const refHeight = canvasHeight / this.props.yTiles;
    this.context.globalAlpha = 1;
    this.context.beginPath();
    for (var x = 0; x <= canvasWidth; x += refWidth) {
      this.context.moveTo(x, 0);
      this.context.lineTo(x, canvasHeight);
    }
    for (var y = 0; y <= canvasHeight; y += refHeight) {
      this.context.moveTo(0, y);
      this.context.lineTo(canvasWidth, y);
    }
    this.context.strokeStyle = '#bcbec0';
    this.context.stroke();
  }

  prepareBaseGridCanvas() {
    let floorAreaRatio;
    if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
      floorAreaRatio = this.props.xTiles / this.props.yTiles;
    } else {
      floorAreaRatio = this.props.yTiles / this.props.xTiles;
    }
    this.canvas = document.getElementById('livemap-basegrid');
    const canvasContainer = document.getElementById('heatmap-container');
    const canvasWidth = canvasContainer.offsetWidth;
    const canvasHeight = canvasContainer.offsetHeight;
    let width;
    let height;
    if (canvasWidth <= canvasHeight) {
      if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
        width = parseInt(canvasWidth * floorAreaRatio);
        height = parseInt(canvasWidth);
      } else {
        width = parseInt(canvasWidth);
        height = parseInt(canvasWidth * floorAreaRatio);
      }
    } else {
      if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
        width = parseInt(canvasHeight * floorAreaRatio);
        height = parseInt(canvasHeight);
      } else {
        width = parseInt(canvasHeight);
        height = parseInt(canvasHeight * floorAreaRatio);
      }
    }
    this.canvas.setAttribute('width', width);
    this.canvas.setAttribute('height', height);
    if (this.props.enableGrid) {
      this.drawBaseGrid();
    }
  }

  prepareLivemapCanvas() {
    let floorAreaRatio;
    if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
      floorAreaRatio = this.props.xTiles / this.props.yTiles;
    } else {
      floorAreaRatio = this.props.yTiles / this.props.xTiles;
    }
    const canvasContainer = document.getElementById('livemap-container');
    const canvasWidth = canvasContainer.offsetWidth;
    const canvasHeight = canvasContainer.offsetHeight;
    let width;
    let height;
    if (canvasWidth <= canvasHeight) {
      if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
        width = parseInt(canvasWidth * floorAreaRatio);
        height = parseInt(canvasWidth);
      } else {
        width = parseInt(canvasWidth);
        height = parseInt(canvasWidth * floorAreaRatio);
      }
    } else {
      if (parseInt(this.props.xTiles) <= parseInt(this.props.yTiles)) {
        width = parseInt(canvasHeight * floorAreaRatio);
        height = parseInt(canvasHeight);
      } else {
        width = parseInt(canvasHeight);
        height = parseInt(canvasHeight * floorAreaRatio);
      }
    }
    this.setState({
      stageSize: {
        width: width,
        height: height,
      },
    });
  }

  removeLiveEvent(key) {
    if (!this.props.fadeLatestEvent) {
      if (this.state.liveEventCollection.length > 1) {
        this.setState(prevState => ({
          liveEventCollection: prevState.liveEventCollection.filter(
            event => event.key !== key
          ),
        }));
        console.log('Removed live event: ' + key);
      }
    } else {
      this.setState(prevState => ({
        liveEventCollection: prevState.liveEventCollection.filter(
          event => event.key !== key
        ),
      }));
      console.log('Removed live event: ' + key);
    }
  }

  removeAlertEvent(key) {
    this.setState(prevState => ({
      alertEventCollection: prevState.alertEventCollection.filter(
        event => event.key !== key
      ),
    }));
    console.log('Removed alert event: ' + key);
  }

  createAlertEvent(event) {
    console.log('Creating alert event');
    const alertEvents = this.state.alertEventCollection.slice();
    const refWidth = this.state.stageSize.width / this.props.xTiles;
    const refHeight = this.state.stageSize.height / this.props.yTiles;
    const x = refWidth * event.xPos;
    const y = refHeight * event.yPos;
    const size = refWidth * radiusFactor;
    const color = '#c91414';
    const eventId = Date.now();
    alertEvents.push(
      <AlertEvent
        key={eventId}
        name={eventId.toString()}
        xPos={(x + size) * (1 / this.state.scaleX)}
        yPos={(y + size) * (1 / this.state.scaleY)}
        size={size}
        onRemove={event => this.removeAlertEvent(event)}
        color={color}
      />
    );
    this.setState({
      alertEventCollection: alertEvents,
    });
  }

  createLiveEvent(event) {
    console.log('Creating live event');
    const liveEvents = this.state.liveEventCollection.slice();
    const refWidth = this.state.stageSize.width / this.props.xTiles;
    const refHeight = this.state.stageSize.height / this.props.yTiles;
    const x = refWidth * event.xPos;
    const y = refHeight * event.yPos;
    const size = refWidth * radiusFactor;
    const color = '#009de0';
    const eventId = Date.now();
    liveEvents.push(
      <LiveEvent
        key={eventId}
        name={eventId.toString()}
        xPos={(x + size) * (1 / this.state.scaleX)}
        yPos={(y + size) * (1 / this.state.scaleY)}
        size={size}
        onRemove={event => this.removeLiveEvent(event)}
        color={color}
      />
    );
    this.setState({
      liveEventCollection: liveEvents,
    });
  }

  render() {
    return (
      <div className="canvas-container" id="livemap-container">
        <canvas className="canvas-basegrid" id="livemap-basegrid" />
        <Stage
          width={this.state.stageSize.width}
          height={this.state.stageSize.height}
          scaleX={this.state.scaleX}
          scaleY={this.state.scaleY}
        >
          <Layer>
            {this.state.liveEventCollection}
            {this.state.alertEventCollection}
          </Layer>
        </Stage>
      </div>
    );
  }
}

export default Livemap;
