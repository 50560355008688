import React, { Component } from 'react';
import SettingsHandler from '../data/settingshandler';
import SessionHandler from '../data/sessionhandler';

class Settings extends Component {
  constructor() {
    super();
    this.state = {
      roomId: '',
      timespan: '',
      enableAlerts: false,
      eventType: '',
      floorAreaX: '',
      floorAreaY: '',
      fadeLatestEvent: false,
      forceValue: '',
      phoneNumber: '',
      smsAlerts: false,
      enableGrid: false,
    };
  }

  componentDidMount() {
    this.settingsChanged = false;
    this.getSettings();
  }

  componentDidUpdate() {
    if (this.settingsChanged) this.setSettings();
  }

  setValue(event, setting) {
    this.settingsChanged = true;
    let value;
    if (event.target.type === 'checkbox') {
      value = event.target.checked;
    } else {
      value = event.target.value;
    }
    this.setState({
      [setting]: value,
    });
  }

  setSettings() {
    SettingsHandler.saveSettings(this.state);
    SessionHandler.clearEvents();
  }

  getSettings() {
    this.setState(SettingsHandler.loadSettings());
  }

  logOut() {
    this.props.onSignOut();
    SessionHandler.clearEvents();
  }

  render() {
    return (
      <div className="settings-content">
        <div className="settings-category">
          <div className="header">
            <div className="title">Global settings</div>
          </div>
          <div className="inputs">
            <label className="label">
              Room id
              <input
                className="input"
                type="number"
                min="0"
                value={this.state.roomId}
                onChange={event => this.setValue(event, 'roomId')}
              />
            </label>

            <label className="label">
              Display event type
              <select
                className="input"
                value={this.state.eventType}
                onChange={event => this.setValue(event, 'eventType')}
              >
                <option value="normal">Normal</option>
                <option value="raw">Raw</option>
              </select>
            </label>

            <label className="label">
              Enable alerts
              <input
                type="checkbox"
                className="input"
                checked={this.state.enableAlerts}
                onChange={event => this.setValue(event, 'enableAlerts')}
              />
            </label>

            <label className="label">
              Fade latest event
              <input
                type="checkbox"
                className="input"
                checked={this.state.fadeLatestEvent}
                onChange={event => this.setValue(event, 'fadeLatestEvent')}
              />
            </label>
            <div className="floor-area">
              <label className="label">
                Floor area (X)
                <input
                  className="input"
                  type="number"
                  min="1"
                  value={this.state.floorAreaX}
                  onChange={event => {
                    this.setValue(event, 'floorAreaX');
                  }}
                />
              </label>
              <label className="label">
                (Y)
                <input
                  className="input"
                  type="number"
                  min="1"
                  value={this.state.floorAreaY}
                  onChange={event => {
                    this.setValue(event, 'floorAreaY');
                  }}
                />
              </label>
            </div>
            <label className="label">
              Enable grid
              <input
                type="checkbox"
                className="input"
                checked={this.state.enableGrid}
                onChange={event => this.setValue(event, 'enableGrid')}
              />
            </label>
            <label className="label">
              Enable SMS alerts
              <input
                type="checkbox"
                className="input"
                checked={this.state.smsAlerts}
                onChange={event => this.setValue(event, 'smsAlerts')}
              />
            </label>
            <label className="label">
              SMS Recipient
              <input
                className="input phoneNumber"
                placeholder="+358501234567"
                value={this.state.phoneNumber || ''}
                onChange={event => {
                  this.setValue(event, 'phoneNumber');
                }}
              />
            </label>
          </div>
        </div>
        <div className="settings-category">
          <div className="header">
            <div className="title">Event history settings</div>
          </div>
          <div className="inputs">
            <label className="label">
              History time span (days)
              <input
                className="input"
                type="number"
                min="1"
                value={this.state.timespan}
                onChange={event => {
                  this.setValue(event, 'timespan');
                }}
              />
            </label>
            <label className="label">
              Heatmap increment value
              <input
                className="input"
                type="number"
                min="0"
                max="1"
                step="0.01"
                value={this.state.forceValue}
                onChange={event => {
                  this.setValue(event, 'forceValue');
                }}
              />
            </label>
          </div>
        </div>
        <div className="setting-commands">
          <p>
            <button
              className="logout-user"
              type="submit"
              onClick={() => this.logOut()}
            >
              Log out
            </button>
          </p>
        </div>
      </div>
    );
  }
}

export default Settings;
