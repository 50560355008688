import React, { Component } from 'react';
import Logo from './../assets/Imagine_IM_logo_2_smaller.png';

class SignIn extends Component {
  logIn(userId, password) {
    if (this.validateCredentials(userId, password)) this.props.onSignIn();
  }

  validateCredentials(userId, password) {
    return true;
  }

  render() {
    return (
      <div className="signin-container">
        <div className="signin-container-shader" />
        <div className="signin-content">
          <div className="logo-container">
            <img src={Logo} className="logo-image" alt="Imagine" />
          </div>
          <div className="signinform-container">
            <p>
              <input type="email" placeholder="E-mail" className="input" />
            </p>
            <div className="password-field">
              <input
                type="password"
                placeholder="Password"
                className="input-password"
              />
              <div className="forgot-password">
                <div>Forgot?</div>
              </div>
            </div>
            <p>
              <input type="checkbox" className="input-checkbox" />
              <label>Keep me logged in</label>
            </p>
            <p>
              <button className="signin-button" onClick={() => this.logIn()}>
                Log in
              </button>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default SignIn;
