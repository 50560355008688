import React, { Component } from 'react';
import TimeSelector from './timeselector/timeselector';
import Retail1 from './../assets/retail_livemap_small.png';
import Retail2 from './../assets/retail_livemap_small_2.png';
import Retail3 from './../assets/retail_livemap_medium.png';
import Retail4 from './../assets/retail_heatmap_large.png';

class Retail extends Component {
  render() {
    return (
      <div className="retail-content">
        <div className="leftside-container">
          <div className="view-container-left-small">
            <div className="header">
              <div className="title">
                <span className="main">Live</span>
                <span className="secondary">[RETAIL AREA 1]</span>
              </div>
            </div>
            <div className="livemap-container">
              <img src={Retail1} alt="Retail 1" />
            </div>
          </div>
          <div className="view-container-right-small">
            <div className="header">
              <div className="title">
                <span className="main">Live</span>
                <span className="secondary">[RETAIL AREA 2]</span>
              </div>
            </div>
            <div className="livemap-container">
              <img src={Retail2} alt="Retail 2" />
            </div>
          </div>
          <div className="view-container-medium">
            <div className="header">
              <div className="title">
                <span className="main">Live</span>
                <span className="secondary">[RETAIL AREA 3]</span>
              </div>
            </div>
            <div className="livemap-container">
              <img src={Retail3} alt="Retail 3" />
            </div>
          </div>
        </div>
        <div className="rightside-container">
          <div className="view-container-large">
            <div className="header">
              <div className="title">
                <span className="main">Heatmap</span>
                <span className="secondary">[RETAIL AREA 2]</span>
              </div>
            </div>
            <div className="heatmap-container">
              <img src={Retail4} alt="Retail 4" />
            </div>
          </div>
        </div>
        <div className="bottom-bar-container">
          <div className="bottom-bar">
            <TimeSelector
              entries={[]}
              onTimeSelected={timestamp => this.selectTimestamp(timestamp)}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Retail;
